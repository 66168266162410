import React from 'react';
import { useField } from 'formik';
import Form from 'react-bootstrap/Form';
import ReactPhoneInput from 'react-phone-input-2';
import { LocationContext } from 'containers/Location/LocationContext';
import ru from 'react-phone-input-2/lang/ru.json';
import cx from 'classnames';
import 'styles/components/phone-input.scss';

interface IProps<Name extends string> {
  name: Name;
  title: string;
}

export const PhoneInput = <T extends string>({ name, title }: IProps<T>) => {
  const [field, meta] = useField(name);
  const isInvalid = meta.touched && !!meta.error;
  const location = React.useContext(LocationContext);
  const handleChange = React.useCallback(
    (value, options, event, formattedValue) => {
      field.onChange({ target: { value: formattedValue, name } });
    },
    [name],
  );

  return (
    <Form.Group>
      <ReactPhoneInput
        localization={ru}
        country={
          location.isReal ? location.countryCode.toLowerCase() : undefined
        }
        specialLabel=""
        containerClass={cx({ 'is-invalid': isInvalid })}
        inputClass={cx({ 'is-invalid': isInvalid })}
        inputProps={{
          name: field.name,
          title: title,
        }}
        {...field}
        onChange={handleChange}
      />
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );
};
